import { MDBBtn, MDBContainer, MDBTextArea } from "mdb-react-ui-kit"
import { generateClient } from "aws-amplify/api";
import { getPost } from "../../graphql/queries";
import { useEffect, useState } from "react";
import { fetchUserAttributes } from 'aws-amplify/auth';
import { useFormik } from "formik";
import Navbar from "../components/Navbar";
import { FaRegTrashAlt } from "react-icons/fa";
import { commentsByPostID } from "../../graphql/queries";
import { addDoc, collection, deleteDoc, doc, DocumentData, getDoc, getDocs, getFirestore, query, serverTimestamp, where } from "firebase/firestore";
import { getApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

const client = generateClient()

function Context() {
    const queryParameters = new URLSearchParams(window.location.search)
    const conID = queryParameters.get("id")

    const [postTitle, setPostTitle] = useState("");
    const [postContent, setPostContent] = useState("");
    const [userID, setUserID] = useState("");
    const [userName, setUserName] = useState("");
    const [comments, setComments] = useState<JSX.Element[]>();
    const [userDisplayName, setUserDisplayName] = useState("");
    const [fbComments, setFbComments] = useState<DocumentData[]>([]);
    const [loggedIn, setLoggedIn] = useState<boolean>(false);

    const app = getApp()
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);
    const authJwt:{name: string, uid: number, email?:string} | null = useAuthUser();

    async function handleFetchUserAttributes() {
        try {
            const userAttributes = await fetchUserAttributes();
            console.log(userAttributes);
            if (userAttributes.sub && userAttributes.name){
                formik.values.usersID = userAttributes.sub
                setUserID(userAttributes.sub);
                setUserName(userAttributes.name);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function fbGetCurrentUser() {
        const auth = getAuth();
        const user = auth.currentUser;

        onAuthStateChanged(auth, (user) => {
            if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/auth.user
                setUserID(user.uid);
                if(user.displayName)
                    setUserDisplayName(user.displayName);
            } else {
            // No user is signed in.
            }
        })
    }

    async function getPostById() {
        if (conID) {
            const onePost = await client.graphql({
                query: getPost,
                variables: { id: conID }
            });
            console.log(onePost)
            if(onePost.data.getPost){
                setPostTitle(onePost.data.getPost.title)
                if (onePost.data.getPost.content)
                    setPostContent(onePost.data.getPost.content)
            }
        }
    }

    async function fbGetPost() {
        if (conID) {
            const docRef = doc(db, "posts", conID);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                setPostTitle(docSnap.data().title)
                setPostContent(docSnap.data().content)
            } else {
                // docSnap.data() will be undefined in this case
                console.log("No such document!");
            }
        }
    }

    async function getCommentsbyPost(){
        if (conID) {
            const oneComments = await client.graphql({
                query: commentsByPostID,
                variables: { postID   : conID }
            });
            if(oneComments.data.commentsByPostID.items){
                setComments(oneComments.data.commentsByPostID.items.map((com: any) => 
                    <MDBContainer className="border my-2">
                        <strong>{com.userName}</strong>
                        <br/><hr/>
                        {com.comment}
                    </MDBContainer>
                ))
            }

            console.log(oneComments.data.commentsByPostID.items)
        }
    }
    
    async function fbGetComments() {
        const q = query(collection(db, "comments"), where("postId", "==", conID));
        const querySnapshot = await getDocs(q);

        if (fbComments.length == 0) {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                fbComments.push(doc)
            });
        }

        setComments(fbComments.map((com: any) => 
            <MDBContainer className="border my-2">
                <strong>{com.data().authorName}</strong>
                <br/><hr/>
                <MDBContainer className="d-flex justify-content-between">
                    {com.data().comment}
                    {(authJwt?.uid == com.data().authorId) && <MDBBtn className="m-2" onClick={() => fbDelComm(com.id)} floating color='danger'><FaRegTrashAlt /></MDBBtn>}
                </MDBContainer>
            </MDBContainer>
        ))
    }

    async function fbDelComm(comId: string) {
        console.log(comId);
        await deleteDoc(doc(db, 'comments', comId));

        window.location.reload();
    }

    useEffect(() => {
        //getPostById();
        //getCommentsbyPost();
        //handleFetchUserAttributes();
        fbGetPost();
        fbGetCurrentUser();
        fbGetComments();
        if (authJwt)
            setLoggedIn(true);
    }, [])

    const onSubmit = async (values: any) => {
        console.log({
            "comment": values.comment,
            "like": values.like,
            "postID": values.postID,
            "usersID": userID
        })
        /*
        if (values.comment) {
            try {
                const newComments = await client.graphql({
                    query: createComments,
                    variables: {
                        input: {
                            "comment": values.comment,
                            "like": values.like,
                            "postID": values.postID,
                            "usersID": userID,
                            "userName": userName
                        }
                    }
                });
                window.location.reload();
            }
            catch (error) {
                console.log(error)
            }
        }
        */

        if( values.comment && conID){
            const docRef = await addDoc(collection(db, "comments"), {
                postId: conID,
                authorId: userID,
                comment: values.comment,
                authorName: userDisplayName,
                createDate: serverTimestamp()
            });
            console.log("Document written with ID: ", docRef.id);
            window.location.reload();
        }
    }

    const formik = useFormik({
        initialValues: {
        usersID: "",
        postID: conID,
        like: 0,
        comment: ""
        },
        onSubmit,
    });
    
    return(
        <MDBContainer style={{maxWidth: "100vw"}}>
            <Navbar/>
            <MDBContainer className="border border-dark rounded my-4">
                <h1>{postTitle}</h1>
                <p>{postContent}</p>
            </MDBContainer>
            {comments && comments}
            {loggedIn && <MDBContainer className="d-flex my-4 justify-content-center flex-column">
                <form className="w-50" onSubmit={formik.handleSubmit}>
                    <h3>Comment:</h3>
                    <MDBTextArea
                    name="comment"
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    rows={4}
                    />
                    <MDBBtn onClick={onSubmit} className="m-2">Submit</MDBBtn>
                </form>
            </MDBContainer>}
        </MDBContainer>
    )
}

export default Context
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import {getAuth, onAuthStateChanged, signInWithEmailAndPassword} from "firebase/auth";
import Navbar from "../components/Navbar";
import {
    Box,
    Container,
    Button,
    TextField,
    Typography,
    InputAdornment,
    IconButton,
    ThemeProvider,
    createTheme, CircularProgress,
} from "@mui/material";
import {Visibility, VisibilityOff} from '@mui/icons-material';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#673ab7',
        },
        secondary: {
            main: '#ff4081',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#ffffff',
            secondary: '#d1c4e9',
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    padding: '12px 30px',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                        transform: 'translateY(-3px)',
                        boxShadow: '0 6px 12px rgba(103, 58, 183, 0.3)',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 10,
                        '& fieldset': {
                            color: '#8050d3',
                        },
                        '&:hover fieldset': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#673ab7',
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: '#d1c4e9',
                    },
                    '& .MuiInputBase-input': {
                        color: '#ffffff',
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#d1c4e9',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                        backgroundColor: 'rgba(103, 58, 183, 0.2)',
                        transform: 'scale(1.1)',
                    },
                },
            },
        },
    },
});

function Login() {


    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const signInJwt = useSignIn();

    useEffect(() => {
        setIsLoading(false)
    }, []);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },

        onSubmit: async (values) => {
            if (values.email && values.password) {
                setErrorMessage("");
                const auth = getAuth();
                try {
                    const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
                    const user = userCredential.user;
                    const token = await user.getIdToken();

                    const signInSuccess = signInJwt({
                        auth: {
                            token,
                            type: "Bearer",
                        },
                        userState: {
                            name: user.displayName,
                            uid: user.uid,
                            email: user.email,
                        },
                    });

                    if (signInSuccess) {
                        navigate("/");
                    } else {
                        setErrorMessage("Failed to set authentication state");
                    }
                } catch (error) {
                    console.error("Error signing in:", error);
                    setErrorMessage("Email or Password is incorrect!");
                }
            } else {
                setErrorMessage("You must fill every field!");
            }
        },
    });

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    if (isLoading) {
        return (
            <ThemeProvider theme={theme}>
                <Box
                    sx={{
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: `
                            radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.1) 0%, rgba(255, 64, 129, 0.1) 90%),
                            radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.1) 0%, rgba(156, 39, 176, 0.1) 90%)
                        `,
                        backgroundSize: 'cover',
                        backgroundAttachment: 'fixed',
                    }}
                >
                    <CircularProgress/>
                </Box>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    background: `
          radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.1) 0%, rgba(255, 64, 129, 0.1) 90%),
          radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.1) 0%, rgba(156, 39, 176, 0.1) 90%)
        `,
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                }}>
                <Navbar/>
                <Container
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#2b2d30',
                            backdropFilter: 'blur(10px)',
                            maxWidth: "400px",
                            width: '100%',
                            padding: 5,
                            borderRadius: 4,
                            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.7)',
                        }}
                    >
                        <form onSubmit={formik.handleSubmit}>
                            <Typography
                                variant="h4"
                                align="center"
                                gutterBottom
                                sx={{
                                    cursor: 'default',
                                    fontWeight: "bold",
                                    padding: "40px 0",
                                    color: '#8050d3',
                                    textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                                    transition: "all 0.3s ease-in-out",
                                    userSelect: 'none',
                                    WebkitUserSelect: 'none',
                                    MozUserSelect: 'none',
                                    msUserSelect: 'none',
                                    "&:hover": {
                                        color: "#9575cd",
                                    },
                                }}
                            >
                                CodeAPeel
                            </Typography>
                            <TextField
                                fullWidth
                                name="email"
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                margin="normal"
                                variant="outlined"
                                type="email"
                            />
                            <TextField
                                fullWidth
                                name="password"
                                label="Password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                margin="normal"
                                variant="outlined"
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleTogglePassword}
                                                edge="end"
                                                sx={{color: 'rgba(255, 255, 255, 0.7)'}}
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {errorMessage && (
                                <Typography color="error" variant="body2">
                                    {errorMessage}
                                </Typography>
                            )}
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                className="mt-4"
                            >
                                Login
                            </Button>
                            <Typography className="text-center mt-2" sx={{
                                userSelect: 'none',
                                WebkitUserSelect: 'none',
                                MozUserSelect: 'none',
                                msUserSelect: 'none',
                                color: '#d1c4e9'
                            }}>
                                Don't have an account?{" "}
                                <Box sx={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.07)',
                                    },
                                }}>
                                    <a href="/signup" style={{color: "#ff4081"}}>
                                        Sign Up
                                    </a>
                                </Box>
                            </Typography>
                        </form>
                    </Box>
                </Container>
            </Box>
        </ThemeProvider>
    );
}

export default Login;
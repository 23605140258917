import React, {useEffect, useState} from "react";
import {AppBar, Toolbar, Button, Grid, Menu, MenuItem, IconButton} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import logoImage from '../../images/codeapeellogo2.png'
import {getAuth} from "firebase/auth";
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useSignOut from 'react-auth-kit/hooks/useSignOut';

const theme = createTheme({
    palette: {
        primary: {
            main: '#673ab7',
        },
        secondary: {
            main: '#ff4081',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#ffffff',
            secondary: '#d1c4e9',
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h6: {
            fontWeight: 500,
        },
        button: {
            textTransform: 'none',
            fontWeight: 500,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'linear-gradient(90deg, #1e1e1e 0%, #2c2c2c 100%)',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    padding: '6px 16px',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        backgroundColor: 'rgba(103, 58, 183, 0.2)',
                        transform: 'translateY(-1px) scale(1.01)',
                    },
                    '&:active': {
                        transform: 'translateY(1px)',
                    }
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        backgroundColor: 'rgba(103, 58, 183, 0.2)',
                        transform: 'rotate(5deg) scale(1.1)',
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        backgroundColor: 'rgba(103, 58, 183, 0.2)',
                        color: '#673ab7',
                        transform: 'scale(1.02)',
                    },
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#1e1e1e',
                },
                list: {
                    padding: 1,
                },
            }
        },
    },
});

function Navbar() {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const isAuthenticated = useIsAuthenticated();
    const auth = useAuthUser();
    const signOut = useSignOut();
    const isMenuOpen = Boolean(anchorEl); // Check if the menu is open

    const handleDropdownOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDropdownClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const checkLoginStatus = () => {
            const userLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
            setIsLoggedIn(userLoggedIn);
        };
        checkLoginStatus();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="static" color="primary" elevation={0}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="home"
                        onClick={() => navigate("/")}
                        sx={{marginRight: 2, padding: 0}}
                    >
                        <img
                            src={logoImage}
                            alt="CodeAPeel Logo"
                            style={{width: 40, height: 40, objectFit: 'contain'}}
                        />
                    </IconButton>
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item>
                            <Button
                                color="inherit"
                                onMouseEnter={handleDropdownOpen}
                                aria-controls="technology-menu"
                                aria-haspopup="true"
                                sx={{
                                    transition: 'all 0.3s ease-in-out',
                                    transform: isMenuOpen ? 'translateY(-2px) scale(1.02)' : 'scale(1)',
                                    backgroundColor: isMenuOpen ? 'rgba(103,58,183,0.2)' : 'transparent',
                                }}
                            >
                                Technology
                            </Button>
                            <Menu
                                id="technology-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleDropdownClose}
                                MenuListProps={{onMouseLeave: handleDropdownClose}}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem onClick={() => {
                                    window.location.href = "https://www.codeapeel.org/CodeAPeel-C-Web/Overview.html";
                                    handleDropdownClose();
                                }}>
                                    CodeAPeel C
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    window.location.href = "https://www.codeapeel.org/CodeAPeel-H-Web/Overview.html";
                                    handleDropdownClose();
                                }}>
                                    CodeAPeel H
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    window.location.href = "https://www.codeapeel.org/CodeAPeel-M-Web/Overview.html";
                                    handleDropdownClose();
                                }}>
                                    CodeAPeel M
                                </MenuItem>
                            </Menu>
                        </Grid>
                        <Grid item>
                            {isAuthenticated && (
                                <Button color="inherit" onClick={() => navigate("/technology")}>
                                    Download
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            <Button color="inherit" onClick={() => navigate("/blog")}>
                                Blog
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="inherit" onClick={() => navigate("/support")}>
                                Support
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="inherit" onClick={() => navigate("/content")}>
                                Content
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="inherit" onClick={() => navigate("/order")}>
                                How to order
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}

export default Navbar;
import { MDBBtn, MDBBtnGroup, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

import { generateClient } from "aws-amplify/api";
import { listPosts, getPost } from "../../graphql/queries"; 
import { useEffect, useState } from "react";
import { fetchUserAttributes } from 'aws-amplify/auth';
import { useFormik } from "formik";
import Navbar from "../components/Navbar";
import { getApp } from "firebase/app";
import { deleteDoc, doc, DocumentData, getFirestore, query, serverTimestamp, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { collection, addDoc, getDocs } from "firebase/firestore";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import Footer from "../components/Footer";
import { FaRegTrashAlt } from "react-icons/fa";

const client = generateClient()

function Blog() {
    const navigate = useNavigate();
    const [printPosts, setPrintPosts] = useState<JSX.Element[]>();
    const [fbPosts, setFbPosts] = useState<DocumentData[]>([]);
    const [userID, setUserID] = useState("");
    const [userDisplayName, setUserDisplayName] = useState("");
    const [loggedIn, setLoggedIn] = useState<boolean>(false);

    const app = getApp()
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);
    const authJwt:{name: string, uid: number, email?:string} | null = useAuthUser();

    async function handleFetchUserAttributes() {
        try {
            const userAttributes = await fetchUserAttributes();
            console.log(userAttributes);
            if (userAttributes.sub)
                setUserID(userAttributes.sub)
        } catch (error) {
            console.log(error);
        }
    }

    async function fbGetCurrentUser() {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
            setUserID(user.uid);
            if(user.displayName)
                setUserDisplayName(user.displayName);
        } else {
        // No user is signed in.
        }
    }

    const getPosts = async () => {
        console.log("allPosts");
        const allPosts = await client.graphql({
            query: listPosts
        });
        console.log(allPosts)
        setPrintPosts((allPosts.data.listPosts.items).map((post: any) => 
            <MDBContainer className="border border-dark rounded py-2 my-4">
                <h4><a href="" onClick={() => {navigate("/context?id=" + post.id)}}>{post.title}</a></h4>
                <p>{post.content}</p>
                <p>Comments: 0 Likes: 0 Views: 0</p>
            </MDBContainer>
        ));
    }

    async function fbGetPosts() {
        const querySnapshot = await getDocs(collection(db, "posts"));
        if (fbPosts.length == 0) {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                fbPosts.push(doc)
            });
        }
        console.log(fbPosts);

        setPrintPosts((fbPosts).map((post: any) => 
            <MDBContainer className="border border-dark rounded py-2 my-4">
                <h4><a href="" onClick={() => {navigate("/context?id=" + post.id)}}>{post.data().title}</a></h4>
                <p>{post.data().content}</p>
                <MDBContainer className="d-flex justify-content-between">
                    <p>Comments: 0 Likes: 0 Views: 0</p>
                    {(authJwt?.uid == post.data().authorId) && <MDBBtn className="m-2" onClick={() => fbDelPost(post.id)} floating color='danger'><FaRegTrashAlt /></MDBBtn>}
                </MDBContainer>
            </MDBContainer>
        ));
    }

    async function fbDelPost(postId: string) {
        console.log(postId);
        await deleteDoc(doc(db, 'posts', postId));

        const q = query(collection(db, "comments"), where("postId", "==", postId));
        const querySnapshot = await getDocs(q);

        await querySnapshot.forEach(async (doc) => {
            fbDelComm(doc.id);
        })
        
        window.location.reload();
    }
    async function fbDelComm(comId: string) {
        console.log(comId);
        await deleteDoc(doc(db, 'comments', comId));
    }

    useEffect(() => {
        //getPosts();
        //handleFetchUserAttributes();
        fbGetCurrentUser();
        fbGetPosts();
        if (authJwt)
            setLoggedIn(true);
    }, [])

    const onSubmit = async (values: any) => {
        if (values.title) {
            /*const newPost = await client.graphql({
                query: createPost,
                variables: {
                    input: {
                        "title": values.title,
                        "content": values.content,
                        "usersID": userID,
                    }
                }
            });*/

            // Add a new document with a generated id.
            const docRef = await addDoc(collection(db, "posts"), {
                title: values.title,
                content: values.content,
                authorName: authJwt?.name,
                authorId: authJwt?.uid,
                createDate: serverTimestamp(),
                comments: []
            });
            console.log("Document written with ID: ", docRef.id);
            //Amplify.configure(config);
            window.location.reload();
        }
    }

    const formik = useFormik({
        initialValues: {
        title: "",
        content: "",
        },
        onSubmit,
      });

    return(
        <MDBContainer className="d-flex align-items-start flex-column" style={{maxWidth: "100vw", height:"100vh"}}>
            <Navbar/>
            <MDBContainer className="mb-auto">
                <MDBRow>
                    <MDBCol size={2} className=" my-4 d-flex justify-content-center" style={{maxHeight:"300px"}}>
                        <MDBBtnGroup vertical aria-label='Vertical button group'>
                            <MDBBtn style={{ backgroundColor: '#cbc3e3' }} outline>Codeapeel C FAQ</MDBBtn>
                            <MDBBtn style={{ backgroundColor: '#def1c3' }} outline>Codeapeel H FAQ</MDBBtn>
                            <MDBBtn style={{ backgroundColor: '#f4cd6c' }} outline>Codeapeel M FAQ</MDBBtn>
                        </MDBBtnGroup>
                    </MDBCol>
                    <MDBCol size={8}>
                        {printPosts && printPosts}
                        {loggedIn && <MDBContainer>
                            <form onSubmit={formik.handleSubmit} style={{maxWidth: "500px"}}>
                                <MDBContainer className="fw-bold px-2 pt-4">
                                    <label>Title</label>
                                    <div className="input-group mb-3">
                                        <input
                                        name="title"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        type="title"
                                        className="form-control mb-2 mx-auto w-75"
                                        />
                                    </div>
                                </MDBContainer> 
                                <MDBContainer className="fw-bold px-2">
                                    <label>Content</label>
                                    <div className="input-group mb-3">
                                        <textarea
                                        name="content"
                                        value={formik.values.content}
                                        onChange={formik.handleChange}
                                        className="form-control mx-auto w-75"
                                        rows={4}
                                        style={{height:"10%"}}
                                        />
                                    </div>
                                </MDBContainer>
                                <button className="btn btn-primary mb-4 mx-auto border my-2" type="submit" onClick={onSubmit}>
                                    Submit
                                </button>
                            </form>
                        </MDBContainer>}
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <Footer/>
        </MDBContainer>
    );
}

export default Blog;
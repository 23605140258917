import {MDBContainer} from "mdb-react-ui-kit";
import {Typography} from "@mui/material";

function Footer() {
    return (
        <footer className="bg-body-tertiary text-center text-lg-start w-100">
            <Typography className="text-center p-3 w-100" style={{
                userSelect: 'none',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
                cursor: 'default',
                color: '#000000',
                backgroundColor: "rgba(0, 0, 0, 0.05)"
            }}>
                All rights reserved. © Algoritix 2024-2025.
            </Typography>
        </footer>
    );
}

export default Footer;